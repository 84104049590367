import { useMemo } from "react";
import { useTimesheet } from "..";
import Style from "../../../../assets/styles";
import { FormifyCombobox } from "../../../../components/Formify/Formify";
import { SHORT_DAY_NAMES } from "../../../../config/costants";
import DICTIONARY from "../../../../config/dict";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { useAuth } from "../../../components/Auth";

const TimesheetHeader = () => {
  const { userData } = useAuth();
  const { selectedMonth, setSelectedMonth, holidays, timesheets, notWorkDays, maybeWorkDays, groupedTimesheet, userList, selectedUser, setSelectedUser } = useTimesheet();

  const dict = DICTIONARY.en.timesheets.TimesheetHeader;

  const jobHours = useMemo(() => timesheets?.reduce((acc, ts) => acc + (ts.activityDate?.substring(0, 7) === selectedMonth.value && ts.type === 'JOB' ? +ts.activityHours : 0), 0), [ timesheets, selectedMonth.value ]);

  const noRecoveryHours = useMemo(() => timesheets?.reduce((acc, ts) => acc + (ts.activityDate?.substring(0, 7) === selectedMonth.value && ts.type === 'NOREC' ? +ts.activityHours : 0), 0), [ timesheets, selectedMonth.value ]);

  const totalHours = useMemo(() => jobHours + noRecoveryHours, [ jobHours, noRecoveryHours ]);

  const totalNeedHours = useMemo(() => {
    return Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).reduce((acc, i) => {
      const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
      const day = new Date(date).getDay();
      if (!notWorkDays.includes(day) && !holidays.includes(date) && !maybeWorkDays.includes(day)) {
        return acc + 8;
      }
      return acc;
    }, 0);
  }, [ selectedMonth, notWorkDays, holidays ]);

  const jobPercent = useMemo(() => {
    const result = Math.round(jobHours / totalHours * 100);
    if (isNaN(result)) return 0;
    return result;
  }, [ jobHours, totalHours ]);

  const noRecoveryPercent = useMemo(() => {
    const result = Math.round(noRecoveryHours / totalHours * 100);
    if (isNaN(result)) return 0;
    return result;
  }, [ noRecoveryHours, totalHours ]);

  const totalPercent = useMemo(() => {
    const result = Math.round((totalHours) / totalNeedHours * 100);
    if (isNaN(result)) return 0;
    return result;
  }, [ totalHours, totalNeedHours ]);

  return (
    <thead className='shadow-md sticky top-0 bg-white'>
      <tr>
        <th className='text-center' colSpan={ 4 }>
          { userData.role === 'ADM' && userList.length > 0 ? (
            <FormifyCombobox
              className={ Style.FormifyCombobox.Table }
              value={ selectedUser }
              onChange={ setSelectedUser }
            >
              { userList.map(({ label, value }) => Style.FormifyCombobox.Table.ComboboxOption({ label, value, isSelected: selectedUser === value })) }
            </FormifyCombobox>
          ) : (
            userData.fullname
          ) }
        </th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth }>
          <input
            className='border-0 focus:ring-0 outline-none text-center w-full'
            type='month'
            value={ selectedMonth.value }
            onChange={ (e) => {
              if (new Date(e.target.value) !== 'Invalid Date') {
                setSelectedMonth(e.target.value);
              }
            } }
          />
        </th>
      </tr>

      <tr>
        <th className='text-center' colSpan={ 4 }></th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth - 6 }>
          { dict.job }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { jobHours }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { jobPercent }%
        </th>
      </tr>

      <tr>
        <th className='text-center' colSpan={ 4 }></th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth - 6 }>
          { dict.noRecovery }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { noRecoveryHours }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { noRecoveryPercent }%
        </th>
      </tr>

      <tr>
        <th className='text-center' colSpan={ 4 }></th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth - 6 }>
          { dict.total }
        </th>
        <th className={ clsx('text-center', totalHours < totalNeedHours ? 'bg-red-300' : totalHours > totalNeedHours ? 'bg-blue-300' : 'bg-green-300') } colSpan={ 3 }>
          { jobHours + noRecoveryHours }/{ totalNeedHours }
        </th>
        <th className={ clsx('text-center', totalHours < totalNeedHours ? 'bg-red-300' : totalHours > totalNeedHours ? 'bg-blue-300' : 'bg-green-300') } colSpan={ 3 }>
          { totalPercent }%
        </th>
      </tr>

      {/** HEADER AND DATES */ }
      <tr>
        <th colSpan={ 4 }></th>
        { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
          const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
          const isToday = date === new Date().toISOString().split('T')[ 0 ];
          const day = new Date(date).getDay();
          const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(date);
          return (
            <th
              key={ `dates-${selectedMonth.value}-${i}` }
              className={
                clsx(
                  isToday && 'ring-inset ring-2 ring-red-500',
                  isNotWorkDay && 'bg-gray-200'
                )
              }
            >
              { i }
            </th>
          );
        }) }
      </tr>

      {/** DAYS OF THE WEEKS */ }
      <tr>
        <th colSpan={ 4 }></th>
        { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
          const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
          const day = new Date(date).getDay();
          const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(date);

          return (
            <th
              key={ `dayofweek-${selectedMonth.value}-${i}` }
              className={ clsx(
                "day",
                isNotWorkDay && 'bg-gray-200'
              ) }
            >
              { SHORT_DAY_NAMES[ day ] }
            </th>
          );
        }) }
      </tr>

      {/** TOTAL PER DAY */ }
      <tr>
        <th>Tipologia</th>
        <th>Commessa</th>
        <th>Bucket</th>
        <th>Attività</th>
        { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
          const date = new Date(selectedMonth.year, selectedMonth.month, i, 12);
          const dateString = date.toISOString().split('T')[ 0 ];
          const isOverToday = date > new Date();
          const total = Object.values(groupedTimesheet).reduce((acc, ts) => {
            return acc + (ts.hours[ dateString ]?.h ?? 0);
          }, 0);
          const day = date.getDay();

          const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(dateString);

          const color = isOverToday ? (
            maybeWorkDays.includes(day) ? (
              total > 0 ? (
                'text-blue-600'
              ) : (
                'text-black'
              )
            ) : (
              total > 0 && total < 8 ? (
                'text-red-600'
              ) : total === 8 ? (
                'text-green-300'
              ) : total > 8 ? (
                'text-blue-600'
              ) : (
                'text-black'
              )
            )
          ) : (
            maybeWorkDays.includes(day) ? (
              total > 0 ? (
                'text-blue-600'
              ) : (
                'text-black'
              )
            ) : (
              total < 8 ? (
                'text-red-600'
              ) : total === 8 ? (
                'text-green-300'
              ) : (
                'text-blue-600'
              )
            )
          );

          return (
            <th
              key={ `totalperday-${selectedMonth.value}-${i}` }
              className={ clsx(
                'text-center w-6 h-6 font-bold',
                color,
                isNotWorkDay && 'bg-gray-200'
              ) }
            >
              { !isNotWorkDay && total }
            </th>
          );
        }) }
      </tr>
    </thead>
  );
};

export default TimesheetHeader;