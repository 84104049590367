import { useChat } from "..";
import { useAuth } from "../../../components/Auth";



const UsersList = () => {
  const { usersList, selectedUser, setSelectedUser } = useChat();
  const { userData } = useAuth();

  return (
    <div className="w-[300px] h-full overflow-y-auto border-r shadow-md">
      {usersList.filter((user) => user.value !== userData.username).map((user) => (
        <div
          key={user.value}
          className={`flex items-center justify-between p-2 cursor-pointer ${selectedUser === user.value ? 'bg-green-200' : 'hover:bg-gray-200'}`}
          onClick={() => setSelectedUser(user.value)}
        >
          <div className="flex items-center">
            <div className="ml-2">{user.label}</div>
          </div>
        </div>
      ))}
    </div>
  );

}

export default UsersList;