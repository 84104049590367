import { createContext, useContext, useState } from "react";
import Style from "../../assets/styles";
import { ContexturePanel } from "../../components/Contexture/Contexture";

const CONTEXT_MENU_OFFSET = 5;

const FloatPanel = () => {
  const { floatPanel, handleClose } = useFloatPanel();

  return (
    <ContexturePanel
      className={ Style.ContexturePanel }
      simple={ true }
      show={ floatPanel.show }
      position={ { x: floatPanel.position.x + CONTEXT_MENU_OFFSET, y: floatPanel.position.y + CONTEXT_MENU_OFFSET } }
      onClose={ handleClose }
    >
      { floatPanel?.Component && (
        <floatPanel.Component data={ floatPanel.data } onClose={ handleClose } />
      ) }
    </ContexturePanel>
  );
};

const FloatPanelContext = createContext();

const FloatPanelProvider = ({ children }) => {
  const [ floatPanel, setFloatPanel ] = useState({
    Component: undefined,
    position: { x: 0, y: 0 },
    data: {},
    show: false
  });

  const value = {
    setFloatPanel: ({ Component, position, data }) => {
      setFloatPanel({ Component, position, data, show: true });
    },
    handleClose: () => setFloatPanel({ ...floatPanel, show: false }),
    floatPanel
  };

  return (
    <FloatPanelContext.Provider value={ value }>
      { children }
			<FloatPanel />
    </FloatPanelContext.Provider>
  );
};

const useFloatPanel = () => useContext(FloatPanelContext);

export default FloatPanel;
export { FloatPanelProvider, useFloatPanel };
