import { createContext, useContext, useEffect, useRef, useState } from 'react';
import config from '../config';
import { useStash } from './Stash/Stash';

/**
 * 
 * @param {string} route - The route for the tracer.
 * @param {object} options - The options for the tracer. (interval, onError, onDepsChange, syncMode)
 * @param {object} deps - The dependencies for the tracer. 
 * @returns {[object, Function, boolean]} - Returns the data, set function, and loading state.
 */
function useTracer(route, options = {}, deps = {}) {
  const { interval = false, onError = false, onDepsChange = false, syncMode = false, onlyIf = true } = options;

  const { ask, follow, unfollow, unregister } = useWebSocket();

  const [ data, setData ] = useStash('wsdata-' + route, []);
  const [ loading, setLoading ] = useState(false);


  useEffect(() => {
    if (!onlyIf) return;

    follow(route, (res) => {
      if (res.route !== route) return;

      if (res.success) {
        const handleAction = {
          "setid": () => {
            setData((prev) => prev.map((item) => item.requestId === res.requestId ? { ...item, id: res.reply.id, requestId: undefined } : item));
          },
          "select": () => {
            setData(res.reply);
            setLoading(false);
          },
          "insert": () => {
            setData((prev) => [ ...prev, { ...res.reply } ]);
          },
          "update": () => {
            setData((prev) => prev.map((item) => item.id === res.reply.id ? res.reply : item));
          },
          "delete": () => {
            setData((prev) => prev.filter((item) => item.id !== res.reply.id));
          },
          'sync': () => {
            ask(route, deps, 'select', true, 0);
          }
        };

        handleAction[ res.action ]?.();
      } else {
        onError && onError(res.error);
        ask(route, deps, 'select', true, 0);
        setLoading(false);
      }
    }, {});

    // Periodically update the data
    let intervalId;
    if (interval) {
      intervalId = setInterval(() => {
        ask(route, deps, 'select', true, 0);
      }, interval);
    }

    // Sync mode
    if (syncMode) {
      ask(route, deps, 'follow', false, 0, true);
    }
    
    // Initial request and register request
    setLoading(true);
    onDepsChange && setData(onDepsChange(data));
    ask(route, deps, 'select', false, 0, true);


    return () => {
      // Disable loading
      setLoading(false);

      // Remove sync mode
      if (syncMode) {
        ask(route, [], 'unfollow', false);
      }
      
      // Remove All Requests
      unfollow(route);
      unregister(route);

      // Clear interval
      clearInterval(intervalId);
    };
  }, [ route, ...Object.values(deps) ]);

  if (!onlyIf) return [ [], () => { }, false ];

  const set = ({ id = undefined, data = undefined }) => {
    if (!id && data) {
      setData((prev) => {
        const requestId = ask(route, data, 'insert', false);
        return [ ...prev, { ...data, requestId } ];
      });
    } else if (id && data) {
      setData((prev) => {
        ask(route, { ...data, id }, 'update', false);
        return prev.map((item) => item.id === id ? { ...data, id } : item);
      });
    } else if (id && !data) {
      setData((prev) => {
        ask(route, { id }, 'delete', false);
        return prev.filter((item) => item.id !== id);
      });
    } else {
        console.error('Invalid arguments');
      }
  };

  return [ data, set, loading ];
}

const WebSocketContext = createContext(null);

/**
 * WebSocket provider component to manage WebSocket connections and requests.
 * @param {object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} - The WebSocket provider component.
 */
const WebSocketProvider = ({ children }) => {
  const socket = useRef(null);
  const handlers = useRef({});
  const activeRequests = useRef([]);
  const requests = useRef([]);
  const reconnectInterval = useRef(null);
  const pingInterval = useRef(null);

  const connectionClosedCount = useRef(0);

  const createWebSocketConnection = () => {
    try {
      socket.current = new WebSocket(config.websocket);
    } catch (error) {
      console.error('WebSocket connection failed:', error);
      return;
    }

    socket.current.onopen = () => {
      console.log('WebSocket connection established');

      pingInterval.current = setInterval(() => {
        if (socket.current && socket.current.readyState === WebSocket.OPEN) {
          socket.current.send('ping');
        }
      }, 1000 * 60 * 5);

      // Clear any existing reconnect intervals
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }

      // Re-send all active requests
      requests.current.sort((a, b) => b.priority - a.priority);
      console.log('requests.current', requests.current);
      requests.current.forEach((pending) => {
        const requestId = generateRequestId();
        const request = { ...pending, requestId };

        activeRequests.current.push(request);

        const sendRequest = () => {
          const send = () => {
            if (socket.current && socket.current.readyState === WebSocket.OPEN) {
              activeRequests.current = activeRequests.current.filter(({ requestId }) => requestId !== request.requestId);

              if (process.env.NODE_ENV === 'development') {
                console.log('WebSocket send:', request);
              }

              socket.current.send(JSON.stringify(request));

              return true;
            }

            return false;
          };

          if (!send()) {
            const interval = setInterval(() => {
              if (send()) {
                clearInterval(interval);
              }
            }, 500);
          }
        };

        if (request.waitForResponse) {
          const send = () => {
            const higherPriorityRequests = activeRequests.current.filter(activerRequest => activerRequest.priority > request.priority && activerRequest.route !== request.route);
            if (higherPriorityRequests.length === 0) {
              sendRequest();

              return true;
            }

            return false;
          };

          if (!send()) {
            const interval = setInterval(() => {
              if (send()) {
                clearInterval(interval);
              }
            }, 500);
          }
        } else {
          sendRequest();
        }
      });

      socket.current.onclose = () => {
        connectionClosedCount.current++;

        if(pingInterval.current) {
          clearInterval(pingInterval.current);
        }

        console.log('WebSocket connection closed.');

        if (!reconnectInterval.current) {
          createWebSocketConnection();

          reconnectInterval.current = setInterval(() => {
            if (socket.current && socket.current.readyState === WebSocket.CLOSED) {
              createWebSocketConnection();
            }
          }, 5000);
        }
      };

    };

    socket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.current.onmessage = (event) => {
      if(event.data === 'pong') {
        return;
      }

      const response = JSON.parse(event.data);

      if (handlers.current[ response.route ]) {
        if (process.env.NODE_ENV === 'development') {
          console.log('WebSocket response:', response);
        }

        handlers.current[ response.route ](response);
      }
    };
  };

  useEffect(() => {
    createWebSocketConnection();

    return () => {
      if (socket.current) {
        socket.current.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
      requests.current = [];
      activeRequests.current = [];
      if(pingInterval.current) {
        clearInterval(pingInterval.current);
      }
    };
  }, []);

  /**
   * Sends a request via WebSocket.
   * @param {string} route - The route for the request.
   * @param {object} data - The data to be sent with the request.
   * @param {string} action - The action type (select, insert, update, delete).
   * @param {boolean} waitForResponse - Whether to wait for a response before sending the request.
   * @param {number} priority - The priority of the request.
   */
  const ask = (route, data = {}, action = 'select', waitForResponse = true, priority = 0, register = false) => {
    if (process.env.NODE_ENV === 'development') {
      // console.log('WebSocket ask:', route, data, action, waitForResponse, priority);
    }

    const requestId = generateRequestId();
    const request = { route, data, action, waitForResponse, priority, requestId, register };
    if (register) {
      requests.current.push({ route, data, action, waitForResponse, priority });
    }

    activeRequests.current.push(request);

    const sendRequest = () => {
      const interval = setInterval(() => {
        if (socket.current && socket.current.readyState === WebSocket.OPEN) {
          activeRequests.current = activeRequests.current.filter(({ requestId }) => requestId !== request.requestId);
          clearInterval(interval);

          if (process.env.NODE_ENV === 'development') {
            console.log('WebSocket send:', request);
          }

          socket.current.send(JSON.stringify(request));
        }
      }, 100);
    };

    const interval = setInterval(() => {
      const higherPriorityRequests = activeRequests.current.filter(req => req.priority > priority && req.route !== route);
      if (higherPriorityRequests.length === 0) {
        clearInterval(interval);

        sendRequest();
      }
    }, 500);

    return requestId;
  };

  const unregister = (route) => {
    requests.current = requests.current.filter(({ route: reqRoute }) => reqRoute !== route);
  }

  /**
   * Registers a handler for a specific route.
   * @param {string} route - The route to follow.
   * @param {Function} handler - The handler function to be called on receiving a response.
   */
  const follow = (route, handler) => {
    handlers.current[ route ] = handler;
  };

  /**
   * Unregisters a handler for a specific route.
   * @param {string} route - The route to unfollow.
   */
  const unfollow = (route) => {
    delete handlers.current[ route ];
  };

  /**
   * Manages WebSocket requests and responses for a specific route.
   * @param {string} route - The route to manage.
   * @param {Function} handler - The handler function to be called on receiving a response.
   * @param {object} options - Options for managing the route.
   * @returns {[Function, Function]} - Returns functions to ask and unfollow the route.
   */
  const account = (route, handler, { lifetime = false, immediateAsk = false, waitForResponse = true, priority = 0, register = false } = {}) => {
    follow(route, handler);

    const _ask = (data = {}, { waitForResponse = true, priority = 0, register = false }) => {
      ask(route, data, 'select', waitForResponse, priority, register);
    };

    const _unfollow = () => {
      unfollow(route);
    };

    if (lifetime) {
      setTimeout(unfollow, lifetime);
    }

    if (immediateAsk) {
      _ask(immediateAsk, { waitForResponse, priority, register });
    }

    return [ _ask, _unfollow ];
  };

  const values = {
    socket: socket.current,
    handlers: handlers.current,
    ask,
    follow,
    unfollow,
    account,
    unregister
  };

  return (
    <WebSocketContext.Provider value={ values }>
      { children }
    </WebSocketContext.Provider>
  );
};

/**
 * Custom hook to use the WebSocket context.
 * @returns {object} - The WebSocket context values.
 */
const useWebSocket = () => {
  return useContext(WebSocketContext);
};

const generateRequestId = () => '_' + Math.random().toString(36).substr(2, 9);

export {
  useTracer, useWebSocket, WebSocketProvider
};
