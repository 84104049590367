import { useState } from "react";
import sls from "react-secure-storage";

const session = (value) => {
  if(!sls.getItem('session')) {
    sls.setItem('session', {
      active: false,
      params: {}
    });
  }

  if(value) {
    sls.setItem('session', value);
  }
  
  return sls.getItem('session');
}

const param = (key, value) => {
  const sess = session();
  if(value) {
    sess.params[key] = value;
    session(sess);
  }
  
  return sess.params[key];
}

const params = (value) => {
  const sess = session();
  if(value) {
    sess.params = value;
    session(sess);
  }
  
  return sess.params;
}

const active = (value) => {
  const sess = session();
  if(value) {
    sess.active = value;
    session(sess);
  }
  
  return sess.active;
}

const store = (key, value) => {
  value && sls.setItem(key, value);
  return sls.getItem(key);
}

const useStash = (key, initial, params) => {
  const [ data, __setData ] = useState(params || store(key) || initial);
  
  const setData = (value) => {
    __setData((curr) => {
      if(value instanceof Function) {
        const newValue = value(curr);
        store(key, newValue);
        return newValue;
      } else {
        store(key, value);
        return value;
      }
    });
  }

  return [ data, setData ];
}

export { active, param, params, store, useStash };
