import { useEffect, useState } from "react";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { useNotifyContext } from "../../../components/Notify";

const Field = ({ value: _value, onChange, disabled }) => {
  const { notify } = useNotifyContext();

  const [ editMode, setEditMode ] = useState(false);
  const [ value, setValue ] = useState(_value);

  const handleEditMode = (enable) => {
    if (enable && !disabled) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const handleSubmit = (e) => {
    handleEditMode(false);
    if (isNaN(+value) || +value > 18 || +value < 0 || value % 0.25 !== 0) {
      notify('Invalid value', 'Please enter a valid number, between 0.25 and 18', 'error');
      setValue(_value);
    } else if ((value === '' && _value === 0) || value === _value) {
      setValue(_value);
    } else {
      onChange(value === '' ? 0 : +value);
      setValue(_value);
    }
  };

  useEffect(() => setValue(_value), [ _value ]);

  return (
    <td className='w-7 h-7 text-center p-0'>
      { editMode ? (
        <input autoFocus
          type='text'
          className='w-full h-full p-0 text-center'
          value={ value }
          onChange={ (e) => setValue(e.target.value) }
          onKeyDown={ (e) => e.key === 'Enter' && handleSubmit(e) }
          onBlur={ handleSubmit }
        />
      ) : (
        <div
          className={ clsx(
            'w-full h-full text-center ring-inset ring-2 ring-transparent',
            disabled ? 'hover:ring-gray-500' : 'hover:ring-blue-500'
          ) }
          onClick={ () => handleEditMode(true) }
        >
          { _value ?? '' }
        </div>
      ) }
    </td>
  );

};

export default Field;