import { useTimesheet } from "..";
import { TIMESHEET_TYPES } from "../costants";
import Field from "./Field";

const Sheet = ({ onChange, onContextMenu, disabled, timesheet }) => {
  const { selectedMonth, holidays, notWorkDays } = useTimesheet();

  const type = TIMESHEET_TYPES.find(({ value }) => value === timesheet.type)?.label ?? '-';

  const job = ![ 'NOREC' ].includes(timesheet.type) ? (timesheet.job ?? '-') : '-';

  const wbs = timesheet.wbs ?? '-';

  const activity = (timesheet.type === 'JOB' ? `[${timesheet.activityCode}] - ` : '') + timesheet.activityDescription ?? '-';

  return (
    <tr className='h-[15px] *:h-[15px] hover:bg-gray-100' onContextMenu={ onContextMenu }>
      <td className='px-2'>{ type } </td>
      <td className='px-2'>{ job }</td>
      <td className='px-2'>{ wbs }</td>
      <td className='px-2'>{ activity }</td>
      { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
        const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
        const day = new Date(date).getDay();
        const currID = timesheet.hours[ date ]?.id ?? undefined;
        const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(date);

        return !isNotWorkDay ? (
          <Field
            disabled={ disabled }
            key={ `field-${timesheet.job}-${timesheet.wbs}-${timesheet.activityCode}-${date}` }
            value={ (timesheet.hours[ date ]?.h ?? 0) > 0 ? timesheet.hours[ date ]?.h : '' }
            onChange={ (val) => {
              onChange({
                id: currID,
                type: timesheet.type,
                job: timesheet.job,
                wbs: timesheet.wbs,
                activityCode: timesheet.activityCode,
                activityDate: date,
                activityHours: val
              });
            } }
          />
        ) : (
          <td key={ date } className='w-7 h-7 text-center bg-gray-200'></td>
        );
      }) }
    </tr>
  );

};


export default Sheet;