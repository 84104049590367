import { createContext, useContext, useMemo, useState } from 'react';
import { SHORT_DAY_NAMES } from '../../../config/costants';
import { useTracer } from '../../../modules/Request';
import { useAuth } from '../../components/Auth';
import { useGenericModal } from '../../components/GenericModal';
import { useNotifyContext } from '../../components/Notify';
import TimesheetBody from './components/TimesheetBody';
import TimesheetHeader from './components/TimesheetsHeader';
import { TIMESHEET_TYPES } from './costants';

const TimesheetContext = createContext();
const useTimesheet = () => useContext(TimesheetContext);

const TimesheetsPage = () => {
  const { notify } = useNotifyContext();
  const { setGenericModal } = useGenericModal();
  const { userData } = useAuth();

  const CURRENT_MONTH = new Date().toISOString().split('T')[ 0 ].slice(0, 7);

  const [ selectedMonth, setSelectedMonth ] = useSelectedMonth(CURRENT_MONTH);
  const [ selectedUser, setSelectedUser ] = useState(userData.username);

  const [ userList ] = useTracer('ts-users', { onlyIf: userData.role === 'ADM' }, {});

  const notWorkDays = [];

  const maybeWorkDays = [ SHORT_DAY_NAMES.indexOf('Sa'), SHORT_DAY_NAMES.indexOf('Do') ];

  const [ activities ] = useTracer('ts-activities', {}, { month: selectedMonth.value });

  const [ calendar ] = useTracer('ts-calendar', {}, { month: selectedMonth.value });

  const holidays = useMemo(() => calendar.reduce((acc, curr) => [ ...acc, curr.holidayDate ], []), [ calendar ]);

  const [ jobs, quotes, norecovery, rdproject ] = useMemo(() => {
    const jobs = [], quotes = [], norecovery = [], rdprojet = [];

    if (!activities) return [ jobs, quotes, norecovery ];

    activities.forEach(activity => {
      if (activity.type === 'JOB') jobs.push(activity);
      else if (activity.type === 'QUOTE') quotes.push(activity);
      else if (activity.type === 'NOREC') norecovery.push(activity);
      else if (activity.type === 'RDPRO') rdprojet.push(activity);
    });

    const sortedJobs = jobs.sort((a, b) => b.job.localeCompare(a.job) || a.wbs.localeCompare(b.wbs) || a.activity - b.activity);
    const sortedQuotes = quotes.sort((a, b) => a.job.localeCompare(b.job) || a.activity - b.activity);
    const sortedNorecovery = norecovery.sort((a, b) => a.activity - b.activity);
    const sortedRdprojet = rdprojet.sort((a, b) => a.activity - b.activity);

    return [ sortedJobs, sortedQuotes, sortedNorecovery, sortedRdprojet ];
  }, [ activities ]);

  const [ timesheets, setTimesheet, loadingTimesheet ] = useTracer('ts-timesheets', { syncMode: true }, { month: selectedMonth.value, username: selectedUser });

  const groupedTimesheet = useMemo(() => {
    if (!timesheets) return {};

    const sorted = timesheets.sort((ts1, ts2) => {
      return TIMESHEET_TYPES.findIndex(({ value }) => value === ts1.type) - TIMESHEET_TYPES.findIndex(({ value }) => value === ts2.type)
        || ts1.job?.localeCompare(ts2.job)
        || ts1.wbs?.localeCompare(ts2.wbs)
        || ts1.activityCode?.localeCompare(ts2.activityCode);
    });

    const grouped = sorted.reduce((acc, ts) => {
      const key = `${ts.job}-${ts.wbs}-${ts.activityCode}`;
      const activityMonth = ts.activityDate?.slice(0, 7);

      if (activityMonth !== selectedMonth.value) return acc;
      if (!acc[ key ]) {
        acc[ key ] = {
          type: ts.type,
          job: ts.job,
          wbs: ts.wbs,
          activityCode: ts.activityCode,
          activityDescription: ts.type === 'JOB' ? (
            jobs.find(curr => curr.type === 'JOB' && curr.job === ts.job && curr.wbs === ts.wbs && curr.activity === ts.activityCode)?.description
          ) : ts.type === 'QUOTE' ? (
            quotes.find(curr => curr.type === 'QUOTE' && curr.job === ts.job && curr.activity === ts.activityCode)?.description
          ) : ts.type === 'NOREC' ? (
            norecovery.find(curr => curr.type === 'NOREC' && curr.activity === ts.activityCode)?.description
          ) : ts.type === 'RDPRO' ? (
            rdproject.find(curr => curr.type === 'RDPRO' && curr.activity === ts.activityCode)?.description
          ) : undefined,
          hours: {},
          pump: jobs.find(curr => curr.type === 'JOB' && curr.job === ts.job && curr.wbs === ts.wbs && curr.activity === ts.activityCode)?.pump,
          completed: jobs.find(curr => curr.type === 'JOB' && curr.job === ts.job && curr.wbs === ts.wbs && curr.activity === ts.activityCode)?.completed
        };
      }
      acc[ key ].hours[ ts.activityDate ] = { d: ts.activityDate, h: isNaN(+ts.activityHours) ? 0 : +ts.activityHours, id: ts.id };
      return acc;
    }, {});


    return grouped;
  }, [ timesheets, selectedMonth ]);


  const handleInsertTimesheet = ({ type, job, wbs, activityCode, activityDate, activityHours }) => {
    const totalHoursForDay = timesheets.reduce((acc, ts) => {
      if (ts.activityDate === activityDate) {
        return acc + +ts.activityHours;
      }
      return acc;
    }, 0);

    if (totalHoursForDay + activityHours > 18) {
      notify('Error', 'Total hours for the day cannot exceed 18', 'error');
      return;
    }

    setTimesheet({ data: { type, job, wbs, activityCode, activityDate, activityHours } });
  };

  const handleUpdateTimesheet = ({ id, type, job, wbs, activityCode, activityHours, activityDate }) => {
    const totalHoursForDay = timesheets.reduce((acc, ts) => {
      if (ts.activityDate === activityDate && ts.id !== id) {
        return acc + +ts.activityHours;
      }
      return acc;
    }, 0);

    if (totalHoursForDay + activityHours > 18) {
      notify('Error', 'Total hours for the day cannot exceed 18', 'error');
      return;
    }

    setTimesheet({ id, data: { type, job, wbs, activityCode, activityHours, activityDate } });
  };

  const handleDeleteTimesheet = ({ type, wbs, job, activityCode }) => {
    setGenericModal({
      label: 'Delete Timesheet',
      description: 'Are you sure you want to delete this row?',
      icon: { name: 'trash', type: 'rr', className: 'text-red-500' },
      submit: {
        label: 'Delete',
        onSubmit: () => {
          setTimesheet({ id: JSON.stringify({ type, wbs, job, activityCode, month: selectedMonth.value }) });
          setGenericModal({ show: false });
        }
      },
      cancel: {
        label: 'Cancel',
        onCancel: ({ handleClose }) => handleClose()
      },
      show: true
    });
  };

  const value = {
    jobs,
    quotes,
    norecovery,
    rdproject,
    activities,

    selectedMonth,
    setSelectedMonth,
    notWorkDays,
    maybeWorkDays,
    holidays,

    groupedTimesheet,
    timesheets,
    loadingTimesheet,

    handleInsertTimesheet,
    handleUpdateTimesheet,
    handleDeleteTimesheet,

    userList,
    selectedUser,
    setSelectedUser
  };


  return (
    <TimesheetContext.Provider value={ value }>
      <TimesheetsView />
    </TimesheetContext.Provider>
  );

};

const TimesheetsView = () => {
  const { loadingTimesheet } = useTimesheet();

  return (
    <div className="flex flex-col items-center w-full h-full overflow-y-auto">
      { loadingTimesheet ? (
        <div className='flex items-center justify-center w-full h-full'>
          <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500'></div>
        </div>
      ) : (
        <table className='w-full border-separate border-spacing-0 [&_td]:border-b [&_th]:border-b [&_th:not(:last-child)]:border-r [&_td:not(:last-child)]:border-r'>
          <TimesheetHeader />

          <TimesheetBody />
        </table>
      ) }
    </div>
  );
};

const useSelectedMonth = (_initValue) => {
  const [ selectedMonth, setSelectedMonth ] = useState(new Date(_initValue) !== 'Invalid Date' ? _initValue : new Date().toISOString().split('T')[ 0 ].slice(0, 7));

  return [
    {
      value: selectedMonth,
      month: +selectedMonth.split('-')[ 1 ] - 1,
      year: +selectedMonth.split('-')[ 0 ],
      daysInMonth: new Date(selectedMonth.split('-')[ 0 ], selectedMonth.split('-')[ 1 ], 0).getDate()
    },
    (value) => {
      if (new Date(value) !== 'Invalid Date') {
        setSelectedMonth(value);
      }
    }
  ];
};


export default TimesheetsPage;
export { useTimesheet };
