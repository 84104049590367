import { useEffect, useMemo } from "react";
import { useTimesheet } from "..";
import Style from "../../../../assets/styles";
import { FormifyCombobox } from "../../../../components/Formify/Formify";
import DICTIONARY from "../../../../config/dict";
import { sieve } from "../../../../modules/Utilkit/Utilkit";
import { useNotifyContext } from "../../../components/Notify";
import { TIMESHEET_TYPES } from "../costants";
import useNewTimesheet from "../hooks/useNewTimesheet";

const TimesheetCreateForm = ({ disabled }) => {
  const { notify } = useNotifyContext();
  const { selectedMonth, timesheets, handleInsertTimesheet, activities } = useTimesheet();

  const defaultValues = { type: '', job: '', wbs: '', activityCode: '' };
  const dict = DICTIONARY.en.timesheets.TimesheetCreateForm;

  const [ newTimesheet, setNewTimesheet ] = useNewTimesheet();

  useEffect(() => {
    setNewTimesheet(undefined);
  }, [ selectedMonth.value ]);

  const handleTypeChange = (val) => setNewTimesheet((curr) => ({
    ...curr,
    type: val,
    job: [ 'NOREC' ].includes(val) ? val : '',
    wbs: [ 'QUOTE', 'NOREC' ].includes(val) ? '-' : [ 'RDPRO' ].includes(val) ? 'none' : '',
    activityCode: ''
  }));

  const handleJobChange = (val) => setNewTimesheet((curr) => ({ ...curr, job: val, activityCode: activities.find(act => act.type === 'RDPRO' && act.job === val)?.activity ?? undefined }));

  const handleWBSChange = (val) => setNewTimesheet((curr) => ({ ...curr, wbs: val }));

  const handleActivityChange = (val) => setNewTimesheet((curr) => ({ ...curr, activityCode: val }));

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!newTimesheet.type || !newTimesheet.activityCode || !newTimesheet.job || !newTimesheet.wbs) {
      return notify('Error', 'Please fill in all fields', 'error');
    }

    const activity = activities.find((act) => {
      return act.type === newTimesheet.type
        && act.job === newTimesheet.job
        && act.wbs === newTimesheet.wbs
        && act.activity === newTimesheet.activityCode;
    });

    if (activity === undefined) return notify('Error', 'Activity not found', 'error');

    const exists = timesheets.some((ts) => {
      return ts?.activityDate?.slice(0, 7) === selectedMonth.value
        && ts?.type === activity.type
        && ts?.job === activity.job
        && ts?.wbs === activity.wbs
        && ts?.activityCode === activity.activity;
    });

    if (exists) return notify('Error', 'Activity is already inserted', 'error');

    handleInsertTimesheet({
      ...newTimesheet,
      activityDate: `${selectedMonth.value}-01`,
      activityHours: 0
    });
    setNewTimesheet(undefined);
  };

  const jobList = useMemo(() => sieve(activities, {
    pre: () => newTimesheet?.type && newTimesheet?.type !== '-',
    filter: (act) => act.type === newTimesheet.type,
    nodouble: (item, curr) => item.value === curr.job,
    passback: curr => ({ value: curr.job, label: curr.job })
  }), [ newTimesheet, activities ]);

  const wbsList = useMemo(() => sieve(activities, {
    pre: () => newTimesheet?.type && newTimesheet?.type !== '-' && newTimesheet?.job && newTimesheet?.job !== '-',
    filter: (act) => act.type === newTimesheet?.type && act.job === newTimesheet.job,
    nodouble: (item, act) => item.value === act.wbs,
    passback: (act) => ({ value: act.wbs, label: act.wbs })
  }), [ newTimesheet, activities ]);

  const activityList = useMemo(() => sieve(activities, {
    pre: () => [ 'NOREC' ].includes(newTimesheet?.type) || (newTimesheet?.job && newTimesheet?.job !== '-' && newTimesheet?.type && newTimesheet?.type !== '-'),
    filter: curr => curr.type === newTimesheet.type && curr.job === newTimesheet.job && curr.wbs === newTimesheet.wbs,
    passback: curr => ({ value: curr.activity, label: `[${curr.activity}] ${curr.description}` })
  }), [ newTimesheet, activities ]);

  const selectedActivity = useMemo(() => newTimesheet && activities.find((curr) => (
    curr.type === newTimesheet.type
    && curr.job === newTimesheet.job
    && curr.wbs === newTimesheet.wbs
    && curr.activity === newTimesheet.activityCode
  )), [ newTimesheet, activities ]);

  const hideJob = [ 'NOREC' ].includes(newTimesheet?.type);
  const disableJob = !newTimesheet?.type;

  const hideWBS = [ 'NOREC', 'QUOTE', 'RDPRO' ].includes(newTimesheet?.type);
  const disableWBS = !newTimesheet?.job;

  const hideActivity = [ 'RDPRO' ].includes(newTimesheet?.type);
  const disableActivity = !newTimesheet?.wbs && ![ 'NOREC', 'QUOTE', 'RDPRO' ].includes(newTimesheet?.type);

  return (
    <tr className='h-[15px] *:h-[15px]'>
      { newTimesheet !== undefined ? (
        <>
          { /* Type Input */ }
          <td className='w-[150px]'>
            <FormifyCombobox
              className={ Style.FormifyCombobox.Table }
              value={ newTimesheet.type }
              onChange={ handleTypeChange }
            >
              { TIMESHEET_TYPES?.length > 0 && TIMESHEET_TYPES.map(({ label, value }) => Style.FormifyCombobox.Table.ComboboxOption({ label, value, isSelected: newTimesheet.job === value })) }
            </FormifyCombobox>
          </td>

          { /* Job Input */ }
          <td className='w-[200px]'>
            { !hideJob ? (
              <FormifyCombobox disabled={ disableJob } className={ Style.FormifyCombobox.Table } value={ newTimesheet.job } onChange={ handleJobChange } >
                { jobList?.length > 0 && jobList.map(({ label, value }) => Style.FormifyCombobox.Table.ComboboxOption({ label, value, isSelected: newTimesheet.job === value })) }
              </FormifyCombobox>
            ) : (
              <div className='px-2'>
                { '-' }
              </div>
            ) }
          </td>

          { /* WBS Input */ }
          <td className='w-[150px]'>
            { !hideWBS ? (
              <FormifyCombobox
                disabled={ disableWBS }
                className={ Style.FormifyCombobox.Table }
                value={ newTimesheet.wbs }
                onChange={ handleWBSChange }
              >
                { wbsList?.length > 0 && wbsList.map(({ label, value }) => Style.FormifyCombobox.Table.ComboboxOption({ label, value, isSelected: newTimesheet.wbs === value })) }
              </FormifyCombobox>
            ) : (
              <div className='px-2'>
                { newTimesheet?.wbs ?? '-' }
              </div>
            ) }
          </td>

          { /* Activity Input */ }
          <td className='w-[300px]'>
            { !hideActivity ? (
              <FormifyCombobox
                disabled={ disableActivity }
                className={ Style.FormifyCombobox.Table }
                value={ newTimesheet.activityCode }
                onChange={ handleActivityChange }
              >
                { activityList?.length > 0 && activityList.map((act) => Style.FormifyCombobox.Table.ComboboxOption({ label: act.label, value: act.value, isSelected: newTimesheet.activityCode === act.value })) }
              </FormifyCombobox>
            ) : (
              <div className='px-2'>
                { [ 'RDPRO' ].includes(newTimesheet?.type) && newTimesheet?.job ? newTimesheet.job : '-' }
              </div>
            ) }
          </td>

          <td className='p-0' colSpan={ selectedMonth.daysInMonth }>
            <div className='flex h-full w-full'>
              <button
                type='button'
                className='flex-1 h-full w-full bg-green-400 text-white'
                onClick={ handleSubmit }
              >
                { dict.add }
              </button>
              <button
                type='button'
                className='flex-1 h-full w-full bg-red-400 text-white'
                onClick={ () => setNewTimesheet(undefined) }
              >
                { dict.cancel }
              </button>
            </div>
          </td>
        </>
      ) : !disabled && (
        <td className='p-0' colSpan={ selectedMonth.daysInMonth + 6 }>
          <div className='flex h-full w-full'>
            <button className='h-full w-full bg-green-400 text-white' type='button' onClick={ () => !disabled && setNewTimesheet(defaultValues) }>
              { dict.addNewRow }
            </button>
          </div>
        </td>
      ) }
    </tr>
  );

};


export default TimesheetCreateForm;
