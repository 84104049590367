import { useState } from "react";

const useNewTimesheet = (initialValue = undefined) => {
  const [ data, setData ] = useState(initialValue ?? undefined);

  return [
    data,
    setData
  ];
};

export default useNewTimesheet;