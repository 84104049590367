import { createContext, useContext, useEffect, useMemo, useState } from "react";
import OtherComp from "./components/OtherComp";

const DebugContext = createContext();
const useDebug = () => useContext(DebugContext);

const Debug = () => {
  // LOGIC
  const [data, setData] = useState('prova');
  const [data2, setData2] = useState('prova 2');

  const variabile = useMemo(() => {
    return variabile;
  }, [data ])

  const convData = useMemo(() => {
    if(!isNaN(+data)) {
      return +data;
    } else {
      return data;
    }
  }, [data]);

  useEffect(() => {
    console.log('123')
  }, []);

  const value = {
    data,
    setData,
    convData
  };

  return (
    <DebugContext.Provider value={ value }>
      <DebugView />
    </DebugContext.Provider>
  );
};

const DebugView = () => {
  // VIEW

  return (
    <div>
      <OtherComp />
    </div>
  );
}

export default Debug;
export { useDebug };
