import { useMemo } from "react";
import { useTimesheet } from "..";
import { useAuth } from "../../../components/Auth";
import { useContextMenu } from "../../../components/ContextMenu";
import Sheet from "./Sheet";
import TimesheetCreateForm from "./TimesheetCreateForm";

const TimesheetBody = () => {
  const { setContextMenu } = useContextMenu();
  const { selectedMonth, selectedUser, handleInsertTimesheet, handleUpdateTimesheet, handleDeleteTimesheet, groupedTimesheet } = useTimesheet();

  const { userData } = useAuth();

  const timesheetValues = useMemo(() => Object.values(groupedTimesheet ?? []), [ groupedTimesheet ]);

  const editNotAllowed = useMemo(() => {
    const selected = new Date(selectedMonth.year, selectedMonth.month + 1, 1, 1);
    const now = new Date();
    const limit = new Date(now.getFullYear(), now.getMonth(), -14, 1); 
    return selected < limit || userData.username !== selectedUser;
  }, [ selectedMonth ]);

  const handleShowContextMenu = (e, timesheet) => {
    e.preventDefault();
    setContextMenu({
      context: [
        [
          {
            id: 'remove',
            name: 'Remove',
            icon: {
              name: 'trash',
              type: 'rr'
            },
            onClick: () => handleDeleteTimesheet({
              type: timesheet.type,
              wbs: timesheet.wbs,
              job: timesheet.job,
              activityCode: timesheet.activityCode
            })
          }
        ]
      ],
      position: {
        x: e.clientX,
        y: e.clientY
      }
    });
  };

  const handleCellValueChange = (payload) => {
    if (payload.id) {
      handleUpdateTimesheet(payload);
    } else {
      handleInsertTimesheet(payload);
    }
  };



  return (
    <tbody>
      {/** TIMESHEETS */ }
      { timesheetValues.map((timesheet) => (
        <Sheet
          key={ `${timesheet.job}-${timesheet.wbs}-${timesheet.activityCode}` }
          timesheet={ timesheet }
          onContextMenu={ (e) => handleShowContextMenu(e, timesheet) }
          onChange={ handleCellValueChange }
          disabled={ editNotAllowed }
        />
      )) }

      <TimesheetCreateForm
        disabled={ editNotAllowed }
      />
    </tbody>
  );

};

export default TimesheetBody;