import { useState } from "react";
import { useChat } from "..";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { useAuth } from "../../../components/Auth";


const ChatBox = () => {
  const { usersList, messagesList, setMessage, loadingMessagesList, selectedUser } = useChat();
  const { userData } = useAuth();

  const [ form, setForm ] = useState('');

  const senderName = usersList.find(user => user.value === selectedUser)?.label ?? '';
  const receiverName = usersList.find(user => user.value === userData.username)?.label ?? '';

  const handleSend = (e) => {
    e.preventDefault();
    const date = new Date().toISOString().replace('T', ' ').substring(0, 19);

    setMessage({ data: { message: form, receiver: selectedUser, date, sender: userData.username } });

    setForm('');
  };

  return (
    <div className="flex flex-col h-full w-full">
      { selectedUser ? (
        loadingMessagesList ? (
          <div className='flex items-center justify-center w-full h-full'>
            <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500'></div>
          </div>
        ) : (
          <>
            <div className="flex-1 overflow-y-auto flex flex-col justify-end">
              { messagesList.length > 0 ? (
                messagesList.map((message, index) => (
                  <div key={ index } className={ clsx(
                    "bg-gray-200 p-2 m-2 rounded-lg flex flex-col w-[500px]",
                    message.sender === userData.username ? 'self-start' : 'self-end'
                  ) }>
                    <div className="font-bold">
                      { message.sender === userData.username ? receiverName : senderName }
                    </div>

                    <div>
                      { message.message }
                    </div>

                    <div>
                      <span className="text-xs text-gray-500 w-full text-end">{ message.date }</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center h-full">
                  { 'Chat is empty' }
                </div>
              ) }
            </div>

            <form
              className="flex items-center"
              onSubmit={ handleSend }
            >
              <input required
                type="text"
                className="flex-1 p-2"
                value={ form }
                onChange={ e => setForm(e.target.value) }
              />
              <button
                className="p-2 bg-blue-500 text-white"
                type="submit"
              >
                Send
              </button>
            </form>
          </>
        )
      ) : (
        <div className="flex justify-center items-center h-full">
          { 'Select a user to start chatting' }
        </div>
      ) }
    </div>
  );
};

export default ChatBox;