import { Transition, TransitionChild } from "@headlessui/react";
import { useEffect } from "react";
import DashboardIcon from "../assets/icons/dashboard.png";
import { clsx } from "../modules/Utilkit/Utilkit";
import ChatPage from "./apps/chat";
import Debug from "./apps/debug";
import TimesheetsPage from "./apps/timesheets";
import { useAuth } from "./components/Auth";
import Navbar, { useNavigator } from "./components/Navigator";
import { useTitleBarTracker } from "./components/TitleBarTracker";

export default function App() {
	useEffect(() => console.log('current deploy: ' + process.env.REACT_APP_STAGE), []);

	const nav = [
		{ id: 'timesheets', name: 'Timesheets', image: DashboardIcon, default: true },
		{ id: 'chat', name: 'Chat', image: DashboardIcon }
	];

	const usernav = [
		{ id: 'logout', name: 'Log Out', style: 'text-red-600', className: "text-red-400", icon: { name: 'sign-out-alt', type: 'rr' } }
	];

	const { isOverlayVisible } = useTitleBarTracker();
	const { authStatus } = useAuth();
	const { go, active } = useNavigator();

	useEffect(() => {
		if(active && !nav.find(n => n.id === active)) {
			go(nav.find(n => n.default)?.id);
		}
	}, [])

	return (
		<div className="relative flex justify-center items-center w-full h-full">
			{ authStatus === "sign-in-required" && isOverlayVisible && (
				<div className="fixed w-full [-webkit-app-region:drag] h-8 top-0 left-0 z-50" />
			) }

			<Transition
				show={ authStatus === 'success' }
				as="nav"
				className="fixed top-0 left-0 z-30 flex justify-start items-center px-1 bg-[#0050a3] h-[env(titlebar-area-height,33px)] w-full duration-150"
				enter="transition duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<Navbar
					nav={ nav }
					usernav={ usernav }
				/>
			</Transition>

			<Transition
				show={ authStatus === 'success' }
				as="main"
				className={ clsx(
					"absolute flex justify-center items-center w-full h-full bg-white",
					authStatus === 'success' ? "z-20" : "z-[0!important]"
				) }
				enter="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				enterFrom="rounded-3xl w-[400px!important] h-[400px!important]"
				enterTo="rounded-0 w-full h-full"
				leave="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				leaveFrom="rounded-0 w-full h-full"
				leaveTo="rounded-3xl w-[400px!important] h-[400px!important]"
			>
				<TransitionChild
					as="div"
					className="w-full h-full"
					enter="transition duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<PageTransition page="timesheets">
						<TimesheetsPage />
					</PageTransition>
					
					<PageTransition page='chat'>
						<ChatPage />
					</PageTransition>
					
					<PageTransition page='debug'>
						<Debug />
					</PageTransition>
				</TransitionChild>
			</Transition>
		</div>
	);
};

const PageTransition = ({ page, children }) => {
	const { active } = useNavigator();

	return (
		<Transition
			show={ active === page }
			as="div"
			className={ clsx("w-full h-full pt-[env(titlebar-area-height,33px)]", active !== page && "absolute") }
			enter="transition duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition duration-300 absolute"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			{ children }
		</Transition>
	);

};