import { useState } from "react";
import { useDebug } from "..";


const OtherComp = () => {
  const { setData, convData } = useDebug();

  const [ value, setValue ] = useState('');

  return (
    <div className="flex flex-col">
      <div>
        { typeof convData } <br />
        { convData }
      </div>
      <hr />
      <input value={ value } onChange={(e) => setValue(e.target.value)} />
      <button onClick={() => setData(value) }>Submit</button>
    </div>
  );
}

export default OtherComp;