import { createContext, useContext, useState } from "react";
import { useTracer } from "../../../modules/Request";
import ChatBox from "./components/ChatBox";
import UsersList from "./components/UsersList";

const ChatContext = createContext();
const useChat = () => useContext(ChatContext);

const ChatPage = () => {
  const [ selectedUser, setSelectedUser ] = useState(false);

  const [ usersList,, loadingUsersList ] = useTracer('ts-users'); // [{value: nome utente, label: descrizione}]
  const [ messagesList, setMessage, loadingMessagesList ] = useTracer('chat', { syncMode: true }, { receiver: selectedUser });

  const value = {
    selectedUser,
    setSelectedUser,

    messagesList,
    setMessage,
    loadingMessagesList,

    usersList,
    loadingUsersList
  }

  return (
    <ChatContext.Provider value={ value }>
      <ChatView />
    </ChatContext.Provider>
  )
}

const ChatView = () => {
  return (
    <div className="flex h-full w-full">
      <UsersList />
      <ChatBox />
    </div>
  )
}

export default ChatPage;
export { useChat };
